import React from 'react';
import './EverythingElse2.css';
import EverythingElse2__LitePaperModule from './EverythingElse2__LitePaperModule.js'
import baseballCap from './img/baseball-cap.png';
// import increase from './img/increase.png';
import increase from './img/bar-chart.png';
import beaker from './img/beaker.png';
import wallet from './img/wallet.png';
import marketing from './img/promotion.png';
// import partnership from './img/partnership.png';
import partnership from './img/deal (1).png';
import transparency from './img/study.png';
import alex from './img/alex.jpg';

const EverythingElse2 = (props) =>{

    return (
        <div className='everything-else2'>
          <p className='everything-else2__content'>
	          <EverythingElse2__LitePaperModule
	              title='Revolutionary Tech Releases'
	              text="
	              		  <br/>
						  Our first will be a non-custodial platform that will have all the convenience of major payment applications (think Venmo and Zelle) and businesses solutions like Yelp and the benefits of a decentalized platform (anonymity, security, complete and direct ownership of assets, etc.). The MVP release will be August 23, 5 weeks out from the PancakeSwap listing.
			              <br/><br/>
			              Are you a business? Get a competitive edge by registering and verifying yours on the platform.
			              <br/><br/>
			              Want to pay your a friend or a non-business individual? Simply type in the users's handle or contact info. Import your contacts.
			              <br/><br/>
			              Centralized exchanges and platforms, like Coinbase and Gemini, have made it easier for individuals purchase cryptocurrency and utilize it for transactions. However, there are serious drawbacks to utilizing a custodial wallet or centralized exchange.
			              Furthermore, payments with the aforementioned platforms is still more difficult than it needs to be, and there is no business directory.
			              <br/><br/>
			              <a href='https://markets.businessinsider.com/currencies/news/crypto-users-pass-100-million-boomers-gen-x-bitcoin-btc-ethereum-2021-2'>Less than 2%</a> of individuals worldwide own any cryptocurrency, and they mostly own Bitcoin. Our goal is to make it so easy to transact with WhaleSafe and other crypto so that we can service some of the 98% individuals who currently don't own any crypto."
	              imgSrc={ wallet }/>
	          <EverythingElse2__LitePaperModule
	              title='First Ever Time-Based Max Ownership Cap (0.5%-2.0%)'
	              text="
						<br/>
	              		<p style='text-align: center; font-size: 24px; font-weight: 700;'>Description</p>
						Our groundbreaking, time-based maximum ownership cap grows each day, starting at 0.5% at launch, and reaching 2.0% at day 75. We call this tokenomic mechanism a 'dynamic whale cap'. This provides several important benefits:
						<br/><br/>
	              			<ol style='width: 75%; margin: 0 auto;'>
	              				<li>There is enhanced protection against snipers, or wallets that take large portions of the coin on the cheap at launch time.</li>
	              				<li>Investors who want to own 2% will end up contributing a lot of value to the market cap and total value invested. They will be motivated to hold for the long term.</li>
	              				<li>Each day, there is an incentive for holders to increase their share beyond the previous day's maximum ownership cap. This buying incentive can fuel daily rallies.</li>
	              			</ol>
	              		<br/><br/>
						<p style='text-align: center; font-size: 24px; font-weight: 700;'>Motivation</p>
	              		A whale is a wallet or individual with a significant share of a crypto asset. Due to the size of their wallets, whales can
	              			dramatically impact the price and success of a crypto asset. Some tokens have absolutely massive whales: Dogecoin has a single wallet with 28.17% of all tokens (as of 2021-07-14). For this reason, we set a cap on the number of tokens a single wallet can hold. We call this a whale cap.
	              			<br/><br/>
	              			However, a simple maximimum ownership cap is not enough. The maximimum ownership cap needs to be dynamic, as the needs of the asset and community change over time.
	              			At launch, the cap should be low, as it is easiest then for individuals to grab a significant share of the token while contributing very little value to the market cap and total amount invested.
	              			These individuals who enter early make massive returns with low stakes, meaning they have little reason to continue to hold or care about the success of the token. When they sell off their profits early, the token's potential for long term success can sometimes evaporate.
	              			<br/><br/>
	              			However, later on, the token and community can benefit from the significant value that wealthier investors can contribute to the market cap. For this reason, the maximimum ownership cap should be higher as the token's value and number of holders increases.
              			"
	              imgSrc={ baseballCap }/>
	          <EverythingElse2__LitePaperModule
	              title='Automatic LP Generation and Reflection, but Low Fees'
	              text='
			              <br/>
			              Automatic LP generation is a great idea. However, the current trend of 10-13% in LP and reflection fees makes these tokens unusable as a form of payment.
				              We do intend for WhaleSafe to be used as a form of payment, via our custom wallet and integration into and partnerships with other platforms and organizations.
				              For this reason, we set the LP fee to 1% and the reflection fee to 0.5%, resulting in just 1.5% in fees on each transaction. Maybe The Gambia can use WhaleSafe as a form of payment.'
	              imgSrc={ beaker }/>
	          <EverythingElse2__LitePaperModule
	              title='Strategic Marketing'
	              text="
			              <br/>
			              Even before the presale, we are influencer and launching targeted social media ad campaigns. Each of the current team members has experience with targeted social media advertising.
			              <br><br>
			              We are reaching out to as many cities and towns as possible via billboard advertising. This is a highly effective and underutilized marketing channel in the crypto space. We have seen time and time again on Reddit, Twitter, and Facebook that crypto communities become extremely excited when they see billboards, yet such billboard advertisements are rare. Current and future WhaleSafe holders should feel heard and seen. Not only do billboards represent an effort by the team to reach to a specific geographical community, but they also add to the product's legitimacy: billboard advertising is a mainstream advertising channel.
						  <br><br>
			              Whenver possible, our ads and social media posts will be educational and analytical. Let's build hype in the best way possible. Follow us on social media to be updated on our efforts."
	              imgSrc={ marketing }/>
	          <EverythingElse2__LitePaperModule
	              title='Partnerships'
	              text="
		              <br/>
		              The team will leverage personal networks, participation in other crypto and technology products, and the token's popularity to make the token a method of payment and utility as widely as possible. Team members have connections to other players in the crypto space and individuals at platforms that could utilize the token as a method of payment. Furthermore, WhaleSafe will be one of a few recommended methods of payment for other products that Alex is building."
	              imgSrc={ partnership }/>
	          <EverythingElse2__LitePaperModule
	              title='Transparency'
	              text="
		              <br/>
		              Our current team is doxxed, and our future team members will be also.
		              <br><br>
		              The combined percentage of coins in the dev (2%) and marketing (1%) wallets is small and known. These wallets and not exempt from the time-based maximum ownership cap or the liquidity and reflection fees.
		              <br><br>We won't make promises that we cannot keep or announce timelines we can't stick to. This has historically been a significant problem in the crypto space, and other BEP20 tokens have promised the world before delivering on a single promise. We have additional plans in store but will not make any further development promises until we are ready to fulfill them in a complete and timely manner."
	              imgSrc={ transparency }/>
	      </p>
        </div>
    );

}

export default EverythingElse2;